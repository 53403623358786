<template>
  <div class="nopermission">
    <img
      src="@/assets/images/noAuth.png"
      alt=""
    >
    <div class="remark">
      <div>暂无登录权限</div>
      <div style="margin-top: 6px"> 请联系管理员进行处理</div>
    </div>
    <span class="describe">如您已创建账号，但未自动关联成功，请进行员工账号关联</span>
    <van-button
      class="btn"
      type="info"
      @click="goConcat"
    >员工账号关联</van-button>
  </div>
</template>

<script>
export default {
  name: 'nopermission',
  methods: {
    goConcat() {
      this.$router.push(`/concatAccount?state=${this.$route.query.state}&redirect=${this.$route.query.redirect}`);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/') { //必要的
      this.$wx.closeWindow();
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.nopermission {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: @gary-white;

  img {
    width: 240px;
    height: 148px;
  }

  .remark {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 40px;
    color: #303133;
    font-weight: bold;
    font-size: 17px;
  }

  .describe {
    width: 295px;
    color: #909399;
    line-height: 20px;
    margin-bottom: 16px;
    text-align: center;
  }

  .btn {
    width: 343px;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 400;
  }
}
</style>
